import { assign, createMachine, send } from 'xstate';
import { defaults, get } from 'lodash-es';

export const entityMachine = (entity) =>
  createMachine(
    {
      id: `matterific.${entity}`,
      initial: 'orchestrating', // we want to start by checking if user is logged in when page loads
      predictableActionArguments: true,

      context: {
        id: null, // the id of the actual document we are using,
        dismissable: null,
        // --- generated by the matter ---
        config: null,
        schema: null,
        uischema: null,
        conditions: null,
        permissions: null,
        computedProperties: null,
        // --- generated by the schema ---
        baseModel: null,
        // --- hydrated by the db ---
        model: null,
        // --- internal/private ---
        error: null,
        validationErrors: null
        // ---
        // additional context can be added when the machine is created
      },

      states: {
        //  try orchestrate the entity through the parent's matter, if it exists
        //  if we get matter back, we can skip the materialize step,
        //  otherwise we need to materialize the entity as normal
        orchestrating: {
          id: 'orchestrating',
          invoke: {
            id: 'orchestrate',
            src: 'orchestrate',
            onDone: { target: 'auth', actions: ['materialize'] },
            onError: { target: 'materializing' }
          }
        },

        // get the entity and materialize into context
        materializing: {
          id: 'materializing',
          invoke: {
            id: 'materialize',
            src: 'materialize',
            onDone: { target: 'auth', actions: ['materialize'] },
            onError: { target: 'unavailable', actions: ['setError'] }
          }
        },

        // Set up the auth machine to check the permissions and conditions
        auth: {
          id: 'auth',
          invoke: {
            id: 'checkAuth',
            src: 'checkAuth'
          },
          exit: send({ type: 'CHECK' }, { to: 'checkAuth' })
        },

        // check the entity and ensure the user has the necessary permissions
        pending: {
          id: 'pending',
          invoke: {
            id: 'pending',
            src: 'check',
            onDone: { target: 'hydrating' },
            onError: [
              {
                target: 'unavailable.permissions',
                actions: ['setError'],
                cond: (context, { data }) => get(data, 'statusMessage') === 'permission-denied'
              },
              {
                target: 'unavailable.conditions',
                actions: ['setError'],
                cond: (context, { data }) => get(data, 'statusMessage') === 'condition-denied'
              },
              { target: 'unavailable', actions: ['setError'] }
            ]
          }
        },

        // fetch the data/defaults and hydrate the model
        hydrating: {
          id: 'hydrating',
          invoke: {
            id: 'hydrate',
            src: 'hydrate',
            onDone: { target: '#available', actions: ['set'] },
            onError: { target: '#unavailable', actions: ['setError'] }
          }
        },

        // the entity is valid and ready to be used
        available: {
          id: 'available',
          initial: 'validating',
          states: {
            validating: {
              invoke: {
                id: 'validate',
                src: 'validate',
                onDone: { target: 'valid', actions: ['clearValidationErrors'] },
                onError: { target: 'invalid', actions: ['setValidationErrors'] }
              }
            },
            valid: {
              on: {
                process: {
                  target: '#processing',
                  actions: ['clearError']
                }
              }
            },
            invalid: {},
            failure: {
              after: {
                // after 5 seconds, clear the error
                5000: { target: 'valid', actions: ['clearError'] }
              }
            }
          },
          on: {
            dismiss: {
              target: '#closed',
              cond: (context) => context.dismissable
            },
            update: { target: '#available.validating', actions: ['set'] },
            validate: { target: '#available.validating' }
          }
        },

        // the entity is processing an action
        processing: {
          id: 'processing',
          invoke: {
            id: 'process',
            src: 'process',
            onDone: { target: 'processed' },
            onError: { target: '#available.failure', actions: ['setError'] }
          }
        },

        // transient state to indicate a successful process
        // we have an imperceptible delay to allow the components to understand the process is complete
        processed: {
          after: {
            100: [
              { target: '#closed', cond: (context) => context.dismissable },
              { target: '#pending', actions: ['setId'] }
            ]
          }
        },

        // the entity is unavailable,
        //   due to not meeting all the conditions
        //   due to not having the appriopriate permissions
        //   due to an error
        unavailable: {
          id: 'unavailable',
          initial: 'error',
          states: {
            conditions: {},
            permissions: {},
            error: {}
          },
          onDone: {
            target: '#closed',
            cond: (context) => context.dismissable
          }
        },

        // the entity has been closed and is no longer valid, but only if its dismissable
        closed: {
          id: 'closed',
          type: 'final'
        }
      },

      on: {
        refresh: {
          target: '#pending',
          actions: ['clear', 'clearError', 'clearValidationErrors']
        }
      }
    },
    {
      actions: {
        materialize: assign((context, { data }) => ({
          config: data.config,
          schema: data.schema,
          uischema: data.uischema?.form,
          baseModel: data.model,
          model: data.model,
          permissions: data.permissions,
          conditions: data.conditions,
          computedProperties: data.computedProperties
        })),

        set: assign({
          model: (context, { data }) => defaults(data, context.baseModel)
        }),
        clear: assign({ model: () => null }),

        setError: assign({
          error: (context, { data }) => data?.message || data || 'Unknown error'
        }),
        clearError: assign({ error: null }),

        setValidationErrors: assign({
          validationErrors: (context, { data }) => data
        }),
        clearValidationErrors: assign({ validationErrors: null }),

        setId: assign({
          id: (context, { data }) => data?.id || context.id // only if it exixts
        })
      },
      services: {
        // services need to be passed in during the machine creation
      }
    }
  );
